/* eslint-disable comma-dangle */
/* eslint-disable lines-around-directive */
//
// STOREFRONT BASE CLIENT SCRIPT
// ----------------------------------------
'use strict';
var base = require('./base');

module.exports = {
    handlerSizeButtons: function () {
        $('body').on('click', '.size-button > button', function (e) {
            e.stopPropagation();
            const value = $(this).attr('value');

            $(this).parent().addClass('selected').siblings().removeClass('selected');
            $('.select-size').find(`option[value="${value}"]`).attr('selected');
            $('.select-size').val(value).trigger('change');
        });
    },
    selectAttribute: function () {
        $(document).off('change', '.select-size');
        $(document).on('change', '.select-size', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-quickview');
            }

            if ($(this).is('select')) {
                base.attributeSelect(e.currentTarget.value, $productContainer);
            }
        });
    }
};
